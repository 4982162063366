<template>
  <v-layout class="shrink" cols="12" align-center justify-center>
    <div class="text-body-2 mr-3">
      {{ $t('main.sign_in_with') }}
    </div>

    <c-btn
      outlined
      color="error"
      icon
      :icon-props="{ icon: '$google' }"
      aria-label="Google sign in"
      @click="googleSignIn()"
    />
  </v-layout>
</template>

<script>
  import { mapState } from 'vuex'
  import { stringify } from 'qs'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import { getSavedCookieParams } from '@/cookie-queries.js'
  import googleAnalytics from '@/plugins/google-analytics.js'

  const { getGoogleAnalyticsClientId, getGoogleAnalyticsSessionId } = googleAnalytics

  export default {
    name: 'GoogleAuthButton',
    components: { CBtn },
    inject: ['settings'],
    computed: {
      ...mapState('settings', ['adNetwork']),
      measurementId() {
        return this.adNetwork.googleAnalyticsMeasurementId
      }
    },
    methods: {
      async googleSignIn() {
        const savedCookies = getSavedCookieParams()
        const googleAnalyticsClientId = await getGoogleAnalyticsClientId(this.measurementId)
        const googleAnalyticsSessionId = await getGoogleAnalyticsSessionId(this.measurementId)
        const registrationParamsFromStorage = sessionStorage.getItem('registration_params')
        const registrationParamsFromCookies = savedCookies.registration_params
        const registrationParamsObjectFromStorage = registrationParamsFromStorage ? JSON.parse(registrationParamsFromStorage) : {}
        const registrationParamsObjectFromCookies = registrationParamsFromCookies ? JSON.parse(registrationParamsFromCookies) : {}

        const combinedRegistrationParamsObject = {
          ...registrationParamsObjectFromCookies,
          ...registrationParamsObjectFromStorage
        }

        const combinedRegistrationParams = Object.keys(combinedRegistrationParamsObject).length ? combinedRegistrationParamsObject : null

        const queryStringParams = {
          ...savedCookies.affiliate_code && { affiliate_code: savedCookies.affiliate_code },
          ...googleAnalyticsClientId && { google_analytics_client_id: googleAnalyticsClientId },
          ...googleAnalyticsSessionId && { google_analytics_session_id: googleAnalyticsSessionId },
          ...(combinedRegistrationParams && { registration_params: combinedRegistrationParams })
        }
        const queryString = stringify(queryStringParams)
        gtmPush({ event: GTM_EVENTS.GOOGLE_SIGN_IN, event_category: GTM_EVENTS_CATEGORY.RETENTION })
        window.location.href = queryString ? `${this.settings.googleSignIn}?${queryString}` : this.settings.googleSignIn
      }
    }
  }
</script>
